const baseurl = {
  stage1baseurl: "https://stage.diro.live/Zuul-1.0/",
  stage2baseurl: "https://stage2.diro.live/Zuul-1.0/",
  prodbaseurl: "https://prod.dirolabs.com/Zuul-1.0/",
};

const stage2 = {
  env: "stage2",
  verifyHash: baseurl.stage2baseurl + "uploadKyc-2.0/verifyhash",
  get_lastclicked_link: baseurl.stage2baseurl + "User-2.0/getlastclickedlink",
  getverificationlink:
    baseurl.stage2baseurl + "organization-2.0/getverificationlinkpoc",
  update_session: baseurl.stage2baseurl + "User-2.0/updatesession",
  getProcessedDataFromChatGpt:
    "https://api2.diro.live/textract/getlivegptfeedback",
  logUrl: "https://trails.diro.live/api/event_logs",
  sentryLogs: "https://utils.diro.live/uploadSentryLogs",
  oneshotupload: baseurl.stage2baseurl + "Oneshotupload-2.0/oneshotupload",
  feedbackurl: baseurl.stage2baseurl + "User-2.0/feedback",
  engagementCallback: "https://api2.diro.live/ube/engagement-status-upload",
  sentryDNS:"https://92bf5e7e118c6ab151ca1b08c650d2d3@o305199.ingest.us.sentry.io/4507372417318912",
  s3Upload:"https://api2.diro.live/org/api/s3uploadCapture",
  imageToPdfApiUrl:"https://img2.diro.live/imagetopdf/image-to-pdf-converter",
}

const stage1 = {
  env: "stage1",
  verifyHash: baseurl.stage1baseurl + "uploadKyc-2.0/verifyhash",
  get_lastclicked_link: baseurl.stage1baseurl + "User-2.0/getlastclickedlink",
  getverificationlink:
    baseurl.stage1baseurl + "organization-2.0/getverificationlinkpoc",
  update_session: baseurl.stage1baseurl + "User-2.0/updatesession",
  getProcessedDataFromChatGpt:
    "https://api1.diro.live/textract/getlivegptfeedback",
  logUrl: "https://trails.diro.live/api/event_logs",
  sentryLogs: "https://utils.diro.live/uploadSentryLogs",
  oneshotupload: baseurl.stage1baseurl + "Oneshotupload-2.0/oneshotupload",
  feedbackurl: baseurl.stage1baseurl + "User-2.0/feedback",
  engagementCallback: "https://api1.diro.live/ube/engagement-status-upload",
  pdfpreview: baseurl.stage1 + "Oneshotupload-2.0/previewdocument?filename=",
  sentryDNS:"https://92bf5e7e118c6ab151ca1b08c650d2d3@o305199.ingest.us.sentry.io/4507372417318912",
  imageToPdfApiUrl:"https://img1.diro.live/imagetopdf/image-to-pdf-converter",
  origin:"https://json1.diro.live",
  s3Upload:"https://api1.diro.live/org/api/s3uploadCapture",
};

const prodeu = {
  env: "prodeu",
  verifyHash: baseurl.prodbaseurl + "uploadKyc-2.0/verifyhash",
  get_lastclicked_link: baseurl.prodbaseurl + "User-2.0/getlastclickedlink",
  getverificationlink:
    baseurl.prodbaseurl + "organization-2.0/getverificationlinkpoc",
  update_session: baseurl.prodbaseurl + "User-2.0/updatesession",
  getProcessedDataFromChatGpt:
    "https://api.diro.io/textract/getlivegptfeedback",
  logUrl: "https://trails.diro.io/api/event_logs",
  sentryLogs: "https://logs.diro.live/uploadSentryLogs",
  oneshotupload: baseurl.prodbaseurl + "Oneshotupload-2.0/oneshotupload",
  feedbackurl: baseurl.stage1baseurl + "User-2.0/feedback",
  engagementCallback: "https://api.diro.io/ube/engagement-status-upload",
  sentryDNS:"https://92bf5e7e118c6ab151ca1b08c650d2d3@o305199.ingest.us.sentry.io/4507372417318912",
  s3Upload:"https://api.diro.io/org/api/s3uploadCapture",
  imageToPdfApiUrl:"https://img.diro.io/imagetopdf/image-to-pdf-converter",
};


const CDN = {
  ...prodeu,    // change to stage1 or prodeu for different environments for the sake of creating
  env: "CDN",
  button: true,
  buttonId: document?.getElementById('reactWidget')?.getAttribute('data-buttonid') || "O.DD-e0SSWg-iByl-sandbox",
  trackId: document?.getElementById('reactWidget')?.getAttribute('data-trackid') || "",
  isWidget: true,  // Add this flag to indicate it's a widget
};

// Add this function to load proper CSS when component initializes
export function initializeEnvironmentCSS() {
  if (CDN.isWidget) {
    // When used as a widget, disable the .App styles and adjust body styles
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .App { display: contents !important; background: none !important; width: auto !important; height: auto !important; }
      
      /* Override index.css body styles for widget mode */
      body.widget-mode {
        height: auto !important;
        width: auto !important;
        margin: inherit !important;
      }

      /* Additional widget mode adjustments */
      .App-header {
        background: none !important;
        min-height: auto !important;
        padding: 10px !important;
        display: flex !important;
        flex-direction: row !important;
      }

      .App-logo {
        height: 24px !important;
        pointer-events: none !important;
      }

      .App-link {
        color: inherit !important;
        text-decoration: underline !important;
      }
    `;
    document.head.appendChild(styleElement);
    
    // Add widget-mode class to body when in widget mode
    document.body.classList.add('widget-mode');
  }
}

export { stage1 };
export { prodeu as env};
export { stage2 };
export { CDN };
