import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./indexTemplate.css";
import { DiroLogo } from "../../helpers";
import Footer from "../view/Footer";

const ThankYouModal = ({ modalColor }) => {

  const LastLinkedClicked = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess
  );

  useEffect(() => {
    let timer;
    if (LastLinkedClicked.data?.redirecturl) {
      timer = setTimeout(() => {
        window.location.href = LastLinkedClicked.data?.redirecturl;
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [LastLinkedClicked]);

  return (
    <div
      className="card-body popup  text-centered p-4 rounded-2"
    
    >
      <div className="thankYou-body">
        <div
          className="text-red fw-bolder"
          style={{ padding: "10px 0px 0px 0px" }}
        >
          <DiroLogo color={LastLinkedClicked?.button?.setcolor}/>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div
            className="upload-title-text"
          >
            Thank you
          </div>
          <div
            className="upload-content-text"
          >
            Submission completed.
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ThankYouModal;
