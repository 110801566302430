import { LIVEFEEDBACK_START, LIVEFEEDBACK_SUCCESS, LIVEFEEDBACK_FAILURE, ALL_LIVEFEEDBACK_DATA, LIVEFEEDBACK_INVALID_DOC, SUBCATEGORY_DOCUMENTCHECK, LIVEFEEDBACK_DATA, PROGRESS_LIVEFEEDBACK_LOADER, IMAGE_UNPROCESSABLE } from "../actions/actionTypes";

const initialState = {
  feedbackStart: false,
  feedbackSuccess: null,
  allLivefeedbackResponses: [],
  processingLivefeedback: false,
  livefeedbackInvalidDoc: false,
  error: null,
  progressLivefeedbackLoader:[]
};



const LiveFeedBack = (state = initialState, action) => {


  switch (action.type) {
    case LIVEFEEDBACK_START:
      return {
        ...state,
        feedbackStart: true,
        feedbackSuccess: null,
        processingLivefeedback: true,
        error: null
      };
    case LIVEFEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackStart: false,
        feedbackSuccess: action.payload,
        processingLivefeedback: false,
        error: null
      };
    case ALL_LIVEFEEDBACK_DATA:
      return {
        ...state,
        allLivefeedbackResponses: action.payload,
        processingLivefeedback: false,
        error: null
      };
    case LIVEFEEDBACK_FAILURE:
      return {
        ...state,
        feedbackStart: false,
        processingLivefeedback: false,
        feedbackSuccess: null,
        error: action.payload
      };
    case LIVEFEEDBACK_INVALID_DOC:
      return {
        ...state,
        livefeedbackInvalidDoc: action.payload
      }
    case LIVEFEEDBACK_DATA:
      return {
        ...state,
        processingLivefeedback: false,
        livefeedbackData: action.payload
      }

    case SUBCATEGORY_DOCUMENTCHECK:
      return {
        ...state,
        subcategorySelected: action.payload.subcategorySelected,
        subcategoryNotSelected: action.payload.subcategoryNotSelected,
        documentSelected: action.payload.documentSelected,
        documentNotSelected: action.payload.documentNotSelected
      }

    case PROGRESS_LIVEFEEDBACK_LOADER:
      return {
        ...state,
        progressLivefeedbackLoader: action.payload
      }

    case IMAGE_UNPROCESSABLE:
      return {
        ...state,
        imageUnprocessable:  action.payload
      }
      
    default:
      return state;
  }
}

export default LiveFeedBack;

// the below code fragment can be found in: