export const FileUploadSvg = ({ color }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200.09 142.88"
    style={{ marginLeft: "39%", marginTop: "0rem", marginRight: "5rem" }}
  >
    {/* <title>file-upload check</title> */}
    <path d="M64.64,13,86.77,36.21H64.64V13ZM42.58,71.67a3.25,3.25,0,0,1-4.92-4.25l9.42-10.91a3.26,3.26,0,0,1,4.59-.33,5.14,5.14,0,0,1,.4.41l9.3,10.28a3.24,3.24,0,0,1-4.81,4.35L52.8,67.07V82.52a3.26,3.26,0,1,1-6.52,0V67.38l-3.7,4.29ZM24.22,85.42a3.26,3.26,0,1,1,6.52,0v7.46H68.36V85.42a3.26,3.26,0,1,1,6.51,0V96.14a3.26,3.26,0,0,1-3.26,3.26H27.48a3.26,3.26,0,0,1-3.26-3.26V85.42ZM99.08,39.19c.15-.57-1.18-2.07-2.68-3.56L63.8,1.36A3.63,3.63,0,0,0,61,0H6.62A6.62,6.62,0,0,0,0,6.62V116.26a6.62,6.62,0,0,0,6.62,6.62H92.46a6.62,6.62,0,0,0,6.62-6.62V39.19Zm-7.4,4.42v71.87H7.4V7.37H57.25V39.9A3.71,3.71,0,0,0,61,43.61Z" />
  </svg>
);

export const DiroLogo = ({ color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={"50px"}
    height={"50px"}
    viewBox="0 0 117.72 117.72"
    style={{ enableBackground: "new 0 0 117.72 117.72" }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
          .st0{fill:${color || "#01A601"};}
        `}
    </style>
    <g>
      <path
        className="st0"
        d="M58.86,0c9.13,0,17.77,2.08,25.49,5.79c-3.16,2.5-6.09,4.9-8.82,7.21c-5.2-1.89-10.81-2.92-16.66-2.92 
          c-13.47,0-25.67,5.46-34.49,14.29c-8.83,8.83-14.29,21.02-14.29,34.49c0,13.47,5.46,25.66,14.29,34.49 
          c8.83,8.83,21.02,14.29,34.49,14.29s25.67-5.46,34.49-14.29c8.83-8.83,14.29-21.02,14.29-34.49c0-3.2-0.31-6.34-0.9-9.37 
          c2.53-3.3,5.12-6.59,7.77-9.85c2.08,6.02,3.21,12.49,3.21,19.22c0,16.25-6.59,30.97-17.24,41.62 
          c-10.65,10.65-25.37,17.24-41.62,17.24c-16.25,0-30.97-6.59-41.62-17.24C6.59,89.83,0,75.11,0,58.86 
          c0-16.25,6.59-30.97,17.24-41.62S42.61,0,58.86,0L58.86,0z M31.44,49.19L45.8,49l1.07,0.28c2.9,1.67,5.63,3.58,8.18,5.74 
          c1.84,1.56,3.6,3.26,5.27,5.1c5.15-8.29,10.64-15.9,16.44-22.9c6.35-7.67,13.09-14.63,20.17-20.98l1.4-0.54H114l-3.16,3.51 
          C101.13,30,92.32,41.15,84.36,52.65C76.4,64.16,69.28,76.04,62.95,88.27l-1.97,3.8l-1.81-3.87c-3.34-7.17-7.34-13.75-12.11-19.63 
          c-4.77-5.88-10.32-11.1-16.79-15.54L31.44,49.19L31.44,49.19z"
      />
    </g>
  </svg>
);

export const getCategory = (category) => {
  if (category === "address") {
    return "utility bill";
  } else {
    return category;
  }
};

export const getSubcatgoryDocumentCheck = (data) => {
  const response = {};
  response.subcategorySelected = data?.button?.subcategory;
  response.subcategoryNotSelected = data?.button?.subcategorynotselected;
  response.documentSelected = data?.button?.documentCheck;
  response.documentNotSelected = data?.button?.documentnotchecked;

  return response;
}


export const correctOptionsForInvalidDoc = (category, liveFeedbackData, subcategorySelected, documentNotSelected, setOptions) => {

  const options = [];
  if (liveFeedbackData?.isCategoryInvalid) {
    options.push(getCategory(category))
  } else if (liveFeedbackData?.isSubCategoryInvalid) {
    options.push(...subcategorySelected);
  } else if (liveFeedbackData?.isDocumentCheckInvalid) {
    options.push(...documentNotSelected);
  }
  setOptions(options);
}


export function convertPngToJpeg(file, quality = 0.95) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.fillStyle = '#fff'; // Fill background with white
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(resolve, 'image/jpeg', quality);
      };
      img.onerror = reject;
      img.src = reader.result; // Load data URL
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
