import React from "react";
import "./statementUploader.css";
import "./statementUploader.css";
import {
  ALL_LIVEFEEDBACK_DATA,
  LIMIT_EXCEED,
  REDIRECT_TO_UPLOAD,
} from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../view/Footer";

const TechnicalErrorIconSvg = ({ color }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#E75151" />
    <path
      d="M13.5089 7.5H16.5177L16.0118 18.0685H13.9882L13.5089 7.5ZM15.0266 23.0769C14.5296 23.0769 14.1124 22.9445 13.7751 22.6798C13.4379 22.4003 13.2692 22.062 13.2692 21.6649C13.2692 21.2677 13.4379 20.9367 13.7751 20.672C14.1124 20.3925 14.5296 20.2528 15.0266 20.2528C15.5237 20.2528 15.9319 20.3925 16.2515 20.672C16.571 20.9367 16.7308 21.2677 16.7308 21.6649C16.7308 22.062 16.5621 22.4003 16.2248 22.6798C15.9053 22.9445 15.5059 23.0769 15.0266 23.0769Z"
      fill="#FCFBFB"
    />
  </svg>
);

var LimitExceed = ({
  allFiles,
  setAllFiles,
  allProcessedFiles,
  setAllProcessedFiles

   
}) => {
  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const imageUpload = useSelector((state) => state.upload.imageUpload);

  const removeFileFromAllFiles = (index) => {
    const temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles([...temp]);
  };

  const removeProcessedFileFromAllProcessedFiles = (index) => {
    const temp = [...allProcessedFiles];
    temp.splice(index, 1);
    setAllProcessedFiles([...temp]);
  };

  const removeLivefeedbackResponseFromAllResponses = (index) => {
    const temp = [...allLiveFeedbackResponses];
    temp.splice(index, 1);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [...temp] });
  };

  const handleDelete = () => {
    removeFileFromAllFiles(allFiles.length - 1);
    removeProcessedFileFromAllProcessedFiles(allProcessedFiles.length - 1);
    removeLivefeedbackResponseFromAllResponses(
      allLiveFeedbackResponses.length - 1
    );
  };

  const redirectToUpload = () => {
    if (allFiles.length < 2) {
      dispatch({ type: REDIRECT_TO_UPLOAD });
    }
  };

  return (
    <div
      className="card-body popup  text-centered p-4 rounded-2"
    
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          alignContent: "stretch",
          flexWrap: "nowrap",
          alignItems: "center",
          // padding:"20px"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <div>
            <TechnicalErrorIconSvg />
          </div>
          <div
            className="upload-title-text"
          >
            Upload failed!
          </div>
          <div
            className="upload-content-text"
          >
           {imageUpload ? "The Image limit exceeded." : "The PDF File limit exceeded."}
          </div>
          <div
            className="upload-content-text"
          >
            {" "}
            {imageUpload ? "The Image size should not exceed 5MB!" : "The PDF File size should not exceed 50MB!"}
          </div>

          <button
            className="upload-button"
            // id="browse"
            onClick={() => {
              dispatch({ type: LIMIT_EXCEED, payload: false });
              handleDelete();
              redirectToUpload();
            }}
          >
            Try Again
          </button>
        </div>
      </div>

      
      <Footer />
    </div>
  );
};

export default LimitExceed;
