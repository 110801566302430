import React from "react";
// import { Modal, ModalBody } from "reactstrap";
import "./indexTemplate.css";
import "../../index.css";

const PleaseWait = ({ modalColor }) => {
  return (
    <div
      className="card-body small-popup  text-centered p-4 rounded-2"
  
    >
      <div className="lds-ellipsis">
        <div
          style={{
            background: modalColor
              ? modalColor
              : "var(--default-color)",
          }}
        ></div>
        <div
          style={{
            background: modalColor
              ? modalColor
              : "var(--default-color)",
          }}
        ></div>
        <div
          style={{
            background: modalColor
              ? modalColor
              : "var(--default-color)",
          }}
        ></div>
        <div
          style={{
            background: modalColor
              ? modalColor
              : "var(--default-color)",
          }}
        ></div>
      </div>

      <div class="card-info text-center mb-3">
        <h6 class="card-title w-75 mx-auto mb-0 lh-base upload-content-text">Please wait</h6>
      </div>
    </div>
  );
};

export default PleaseWait;
