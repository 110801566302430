import React, { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import shoppingCartImg from "../Components/assets/shopping-cart-svgrepo-com.svg";
import LiveFeedback from "./view/LiveFeedbackPopup";
import {
  ALL_LIVEFEEDBACK_DATA,
  REDIRECT_TO_UPLOAD,
} from "../actions/actionTypes";
import doneIcon from "../images/ic_done2.png";
import Footer from "./view/Footer";

// New Check SVG component
const CheckIcon = ({ height = 40, width = 40 }) => (
  <svg width={width} height={height} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="256" cy="256" r="256" fill="#00B300" />
    <path d="M393.544 165.956L226.646 332.854L118.456 224.664L86.248 256.872L226.646 397.27L425.752 198.164L393.544 165.956Z" fill="white" />
  </svg>
);

export default function UploadStatus({
  handleBrowseClick,
  handleFileSelect,
  fileInputRef,
  oneShotUploadAction,
  allFiles,
  setAllFiles,
  allProcessedFiles,
  setAllProcessedFiles,
  progressLiveFeedback
}) {
  const dispatch = useDispatch();

  const [loaderValue, setLoaderValue] = useState(0);

  const lastLinkClickedData = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess
  );

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const documentIsNotOriginal = useSelector(
    (state) => state?.upload?.documentIsNotOriginal
  );

  const handleSubmit = () => {
    oneShotUploadAction();
  };

  // Function to handle click of try another button and to delete the file and the liveFeedback data of the selected file and to select another file.
  const handleTryAnother = () => {
    setAllFiles([]);
    setAllProcessedFiles([]);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [] });
    // handleBrowseClick();
    dispatch({ type: REDIRECT_TO_UPLOAD });
  };

  // check even if a single document is invalid
  const checkEvenIfADocIsInvalid = () => {
    if (
      allLiveFeedbackResponses.find((item) =>
        item.document_type.toLowerCase().includes("Invalid".toLowerCase())
      )
    ) {
      return true;
    }
    return false;
  };

  const verifiedData = useSelector((state) => state.upload?.uploadedFile);

  const data = [
    { field: "Document ID", key: "docid" },
    { field: "Generated On", key: "generatedon" },
    { field: "Document fingerprint", key: "hash" },
    { field: "Original Source", key: "originalsource" },
  ];

  useEffect(() => {
    if (fileInputRef) {
      fileInputRef.current?.addEventListener("cancel", (evt) => {
        dispatch({ type: REDIRECT_TO_UPLOAD });
      });
    }
    return () => {
      fileInputRef.current?.removeEventListener("cancel", (evt) => { });
    };
  }, [handleFileSelect]);
const getClassName = () =>{
  if(documentIsNotOriginal){
    return "card-body text-centered p-4 rounded-2 popup"
  }else{
    return "card-body text-centered p-4 rounded-2"
  }
}
  return (
    <div className={getClassName()}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <div>
          {!documentIsNotOriginal && verifiedData && !verifiedData?.error && (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                flexDirection: "column",
                width: "750px",
              }}
            >
              <img src={doneIcon} className="verified-res-img" />
              <h5
                className="text-red mb-4 fw-bolder ws-normal-m"
                style={{
                  fontSize: "22px",
                  fontFamily: "system-ui",
                  fontWeight: "bolder",
                  lineHeight: "2rem",
                }}
              >
                The original document is successfully verified!
              </h5>

              <div>
                {verifiedData &&
                  !verifiedData?.error &&
                  data?.map((item, index) => {
                    return (
                      <div className="verified-res-fields" key={index}>
                        <div
                          className="verified-res-field-title"
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {item.field}:
                        </div>
                        <p
                          className="verified-res-field-value"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            // fontFamily: "Montserrat",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {item?.key === "generatedon"
                            ? String(
                              new Date(
                                parseInt(verifiedData?.data[item?.key])
                              )
                            )
                            : verifiedData?.data[item?.key]}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <div 
          style={{
            display: documentIsNotOriginal ? "block" : "none",
          }}
        >
          {lastLinkClickedData?.button?.multidownload ? (
            <CircularProgressbarWithChildren
              value={loaderValue}
              styles={buildStyles({
                pathColor: "green",
              })}
            >
              <img height={100} src={shoppingCartImg} alt="cart" />
            </CircularProgressbarWithChildren>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignItems: "center",
                width: "350px",
              }}
            >
              <CheckIcon height={40} width={40} />
              <div className="upload-title-text">
                Document uploaded
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            margin: "20px 0px",
            paddingLeft: "20px",
            display: documentIsNotOriginal ? "block" : "none",
            width: "385px",
          }}
        >
          <LiveFeedback
            setLoaderValue={setLoaderValue}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
            allProcessedFiles={allProcessedFiles}
            setAllProcessedFiles={setAllProcessedFiles}
          />
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          {lastLinkClickedData?.button?.multidownload && (
            <button
              className="upload-button"
              onClick={handleBrowseClick}
            >
              {allFiles?.length > 0 ? "upload more" : "upload"}
            </button>
          )}

          {!lastLinkClickedData?.button?.multidownload && (
            <button
              className="upload-button"
              onClick={handleTryAnother}
            >
              Try another
            </button>
          )}
          <input
            ref={fileInputRef}
            className="d-none"
            type="file"
            onChange={handleFileSelect}
          // multiple={lastLinkClickedData?.button?.multidownload}
          />

          <button
            className="upload-button" 
            onClick={handleSubmit}
            disabled={!allFiles?.length}
            style={{display: documentIsNotOriginal ? "block" : "none"}}
          >
            {checkEvenIfADocIsInvalid() ? "Submit anyway" : "Submit"}
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}