import React from "react";
import "./statementUploader.css";
import {
  ALL_LIVEFEEDBACK_DATA,
  IMAGE_UNPROCESSABLE,
  LIVEFEEDBACK_INVALID_DOC,
  REDIRECT_TO_UPLOAD,
  SOMETHING_WENT_WRONG,
} from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import TechnicalErrorIconSvg from "../../helpers/technicalError";
import Footer from "../view/Footer";


const ImageToPdfFailed = ({ allFiles }) => {
  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );


  const redirectToUpload = () => {

    dispatch({ type: REDIRECT_TO_UPLOAD });
    dispatch({ type: 
      IMAGE_UNPROCESSABLE, payload:false  });
  };
 
const lines = [
    " Document is centered and well-lit",
    " All corners are clearly visible",
    " Background is dark and clean",
    " Document is not tilted",
    " Paper is flat (no folds)"];
  return (
    <div
      className="card-body popup  text-centered p-4 rounded-2"
    
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          alignContent: "stretch",
          flexWrap: "nowrap",
          alignItems: "center",
          // padding:"20px"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <div>
            <TechnicalErrorIconSvg />
          </div>
          <div
            className="upload-title-text"
          >
            Unproccessable Image!
          </div>
          <div
            className="upload-content-text" style={{ textAlign: "left" }}
          >
            Unable to process document. Please ensure:
            {lines?.length > 0 && (
              <ol>
                {lines.map((line, index) => (
                  <li key={index}>{line.trim()}</li>
                ))}
              </ol>
            )}
          </div>

          <button
            className="upload-button"
            // id="browse"
            onClick={
              redirectToUpload}
          >
            Try Again
          </button>
        </div>
      </div>

      
      <Footer />
    </div>
  );
};

export default ImageToPdfFailed;
